.top-bar .cmp.cmp-search-box {
    //background-color: $cfourSecondaryColor;
    background-color: transparent;
    max-width: 1200px;
    margin: auto;
    ///border-top: 1px solid $cfourWhite;
    z-index: 99999;
    .search-submit {
        color: $cfourPrimaryColor;
    }
    .search-box-inner {
        display: flex;
        flex-flow: row-reverse;
        .search-box-shadow-frame {
            padding: 0 0 0 7px;
            max-width: 50%;
            display: flex;
            /*flex: 100;*/
            border-top: 1px solid #fff;
            & > .container-max {
                padding: 0;
            }
        }
    }
}

.furniture {
    .top-bar .cmp.cmp-search-box {
        .search-submit {
            color: $cfourFurniturePrimaryColor;
        }
    }
}
